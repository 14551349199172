'use client'

import { type EVENT_TYPES, type EventProperties, trackEvent } from '@/lib/trackEvent'
import { useEffect } from 'react'

type TrackEventOnceProps = {
  type: EVENT_TYPES
  properties?: EventProperties
}
function TrackEventOnce({ type, properties }: TrackEventOnceProps) {
  useEffect(() => {
    trackEvent(type, properties)
  }, [type, properties])

  return null
}

export default TrackEventOnce
